import React, { useState } from "react";
import { IconButton } from "@chakra-ui/react";
import { useInterval } from "usehooks-ts";
import { Timebox } from "./model/timeboxes";
import { CloseIcon } from "@chakra-ui/icons";
import ToLink from "./ToLink";
import useSound from 'use-sound';


function MyTimeboxView({ timebox, onStop }: { timebox: Timebox, onStop: () => void }) {
  const [remainingSeconds, setRemainingSeconds] = useState<number>(0);
  const [play] = useSound("/sound/hint.wav");

  useInterval(() => {
    const nowInMilliseconds = new Date().getTime();
    // const finishedInSeconds = 0;
    const finishedAt = timebox.finishedAt;
    const finishedInMilliseconds = finishedAt.getTime();
    const remainingSeconds = Math.floor((finishedInMilliseconds - nowInMilliseconds) / 1000);
    if (remainingSeconds === 60) {
      play();
    }
    setRemainingSeconds(remainingSeconds);
  }, 1000);

  const twoDigitString = (num: number) => {
    num = Math.floor(num);
    if (num > 9) {
      return `${num}`;
    }
    return `0${num}`;
  };

  const secondsToTime = (remainingSeconds: number) => {
    let minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds - minutes * 60;

    const hours = Math.floor(minutes / 60);
    minutes = minutes - hours * 60;

    const suffix = `${twoDigitString(minutes)}:${twoDigitString(seconds)}`;

    if (hours === 0) {
      return suffix;
    }
    return `${hours}:${suffix}`;
  };

  return (
        <div style={{ marginTop: 40, marginBottom: 25 }}>
          <div style={{ margin: "auto", textAlign: "center" }}>
            <h4>Current Timebox</h4>
            <div>
              {timebox.description
                ? timebox.description
                : "(no description)"}{" "}
              {timebox.location
                ? <p><small><ToLink>{timebox.location}</ToLink></small></p>
                : null }
              {timebox.detail
                ? <p>{timebox.detail}</p>
                : null }
              <p>{timebox.private ? "(private)" : "(public)"}</p>
            </div>
            <div style={{ margin: "auto", textAlign: "center" }}>
              {secondsToTime(remainingSeconds)}
            </div>
          <IconButton icon={<CloseIcon />} onClick={onStop} aria-label={"polite"} />
          </div>
        </div>
  );

}

export default MyTimeboxView;
