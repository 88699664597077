import { useState } from 'react'
import { supabase } from './supabaseClient'
import { Heading, Input, Button } from '@chakra-ui/react'

export default function Auth() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const handleLogin = async (e:React.SyntheticEvent) => {
    e.preventDefault()

    try {
      setLoading(true)
      const { error } = await supabase.auth.signIn({ email })
      if (error) throw error
      alert('Check your email for the login link!')
    } catch (error: any) {
      alert(error?.error_description || error?.message)
    } finally {
      setLoading(false)
    }
  }
  const signInWithGoogle = async (e: React.SyntheticEvent) => {
    /*const { user, session, error } = */ await supabase.auth.signIn({
      provider: 'google',
    })
    console.log('after auth.signIn');
  }

  return (
    <div className="row flex flex-center">
      <div className="col-6 form-widget" aria-live="polite">
        <Heading>Tomatina.io</Heading>
        <p className="description">Sign in via magic link with your email below</p>
        {loading ? (
          'Sending magic link...'
        ) : (
      <div>
          <form onSubmit={handleLogin}>
            <label htmlFor="email">Email</label>
            <Input
              id="email"
              className="inputField"
              type="email"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button type="submit" aria-live="polite">
              Send magic link
            </Button>
          </form>
          <br />

        <Button onClick={signInWithGoogle} name="googleSignIn">Sign in with Google</Button>
        </div>
        )}
      </div>
    </div>
  )
}