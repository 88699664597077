import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import React from "react";
import { CalendarItemProps } from "./CalendarItemList";
import ToLink from "./ToLink";
import { prettyDate } from "./util/dateTime";


interface EventModalProps {
    event: CalendarItemProps;
}

function EventModal({event}: EventModalProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()
  
    const btnRef = React.useRef() as any;
    return (
      <>
        <Button mt={3} ref={btnRef} onClick={onOpen}>
          Details
        </Button>
  
        <Modal
          onClose={onClose}
          finalFocusRef={btnRef}
          isOpen={isOpen}
          scrollBehavior={"inside"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{event.summary}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p><small>{`${prettyDate(event.start)} - ${prettyDate(event.end)}`}</small></p>
              {event.location ? <p><b>Location: </b> <ToLink>{event.location}</ToLink></p> : null }
              {event.description ? (<p><b>Description: </b> 
              <div dangerouslySetInnerHTML={{ __html: event.description}} /></p>) : null }
              <p><small>{event.attendeeEmailAddresses.join(", ")}</small></p>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  export default EventModal;