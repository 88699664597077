import create from 'zustand';
import { Profile } from '../model/profiles';

interface MyProfileState {
    profile: Profile | null;
    set: (t: Profile) => void;
    unset: () => void;

}

const useStore = create<MyProfileState>(set => ({
  profile: null,
  set: (newProfile: Profile) => {
      set((state) => {
          if (state.profile) {
              console.error("profile is already set!");
              return {profile: state.profile};
          }
          return {profile: newProfile};
      })
  },
  unset: () => {
      set((state) => {
          return {profile: null};
      })
  }
}));
export const useMyProfileStore = useStore;