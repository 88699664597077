import { useEffect, useState } from 'react'
import { supabase } from './supabaseClient'
import { Avatar as ChakraAvatar, AvatarBadge } from "@chakra-ui/react";

interface AvatarProps {
  url?: string;
  online: boolean;
}
export default function Avatar({ url, online }: AvatarProps) {
  const [avatarUrl, setAvatarUrl] = useState(null)
  const bgColor = online ? 'green.500' : 'gray.500';

  useEffect(() => {
    if (url) downloadImage(url)
  }, [url])

  const downloadImage = async (path: string) => {
    try {
      const { data, error } = await supabase.storage.from('avatars').download(path)
      if (error) {
        throw error
      }
      const url = URL.createObjectURL(data as any)
      setAvatarUrl(url as any)
    } catch (error: any) {
      console.log('Error downloading image: ', error.message)
    }
  }
  const size = 30;

  return (
      <ChakraAvatar src={avatarUrl || `https://place-hold.it/${size}x${size}`}>
      <AvatarBadge boxSize='1.25em' bg={bgColor} />
      </ChakraAvatar>
  )
}