import { CheckCircleIcon } from "@chakra-ui/icons";
import { Flex, IconButton } from "@chakra-ui/react";
import {SiGooglecalendar} from "react-icons/si";

interface GoogleCalendarConnectionViewProps {
  connected: boolean;
  connect: () => void;
}

const GoogleCalendarConnectionView = (props: GoogleCalendarConnectionViewProps) => {
  return (
    <div>
      {props.connected ? (
        <Flex>
          <SiGooglecalendar /><CheckCircleIcon color={"green"} />
        </Flex>
      ) : (
        <Flex>
          <IconButton
            icon = {<SiGooglecalendar />} 
            aria-label='Call Sage'
            onClick={() => {
              props.connect();
            }}
          >
            Sign in to Google Calendar
          </IconButton>
        </Flex>
      )}
    </div>
  );
};

export default GoogleCalendarConnectionView;