import { supabase } from '../supabaseClient'
import { getTimezone, getTimezoneOffset } from '../timezone'
import { DateTime } from "luxon";

export interface Profile {
  id: string | undefined;
  username: string;
  email: string;
  avatarURL: string | undefined;
  timezone: string | undefined;
  timezoneOffset: number | undefined;
  updatedAt: Date;
}

  const rowToUser = (data: any): Profile => {
    return {
      id: data.id,
      username: data.username,
      avatarURL: data.avatar_url,
      email: data.email,
      timezone: data.timezone,
      timezoneOffset: data.timezone_offset,
      updatedAt: new Date(data.updated_at),
    };
  }


  interface UserUpdateProps {
    id: string;
    username?: string | null;
    avatar_url?: string | null;
    emailAddress?: string | null;
  }
  export const upsert = async (props: UserUpdateProps) => {

      const updates = {
        id: props.id,
        username: props.username,
        avatar_url: props.avatar_url,
        email: props.emailAddress,
        updated_at: new Date(),
        timezone: getTimezone(),
        timezone_offset: getTimezoneOffset(),
      }

      console.log("profile updates: ", updates);
      
      return await supabase.from('profiles').upsert(updates, {
        returning: 'representation',
      });
    };

export const fetchSelf = async () => {

        const user = supabase.auth.user()
        if (!user) {
          throw new Error("user is unknown in fetchSelf");
        }
        const userId = user.id;

        const row = await supabase
          .from('profiles')
          .select(`id, username, avatar_url, email, timezone, timezone_offset, updated_at`)
          .eq('id', user?.id)
          .single() 

        if (row.error) {
          if (row.error.details === "Results contain 0 rows, application/vnd.pgrst.object+json requires 1 row") {
            console.log('no profile. creating...');
            const profile = await upsert({
              id: userId,
              username: user.email,
              emailAddress: user.email,
            });
            return rowToUser(profile);
            
          }
          console.log("error from fetchSelf row.error: ", row.error);
          throw row.error;
        }

        if (!row.data) {
            throw new Error("no data in response");
        }

        return rowToUser(row.data);
}


export const touch = async () => {
    const update = {
      timezone: getTimezone(),
      timezone_offset: getTimezoneOffset(),
      updated_at: new Date(),
    };
    // console.log("tz update: ", update);
    
    await supabase.from("profiles").update(update, {
      returning: "minimal",
    }).eq("id", getUserId());
}

export const fetchOthers = async () => {
      const res = await supabase
        .from("profiles")
        .select("id, username, avatar_url, email, timezone, timezone_offset, updated_at")
        .neq("id", getUserId());
    if (res.error) {
        throw res.error;
    }
    if (!res.data) {
        throw new Error("no data in response");
    }
      const users: Array<Profile> = [];
      for (let userRow of res.data) {
        users.push(rowToUser(userRow));
      }
      return users;

}

export const fetchUsers = async () => {
      const res = await supabase
        .from("profiles")
        .select("id, username, avatar_url, email, timezone, timezone_offset, updated_at")
    if (res.error) {
        throw res.error;
    }
    if (!res.data) {
        throw new Error("no data in response");
    }
      const users: Array<Profile> = [];
      for (let userRow of res.data) {
        users.push(rowToUser(userRow));
      }
      return users;

}

export const recent = (lastUpdate: Date) => {
  const minutesAgo3 = DateTime.now().minus({minutes: 3}).toJSDate();
  const recent = (minutesAgo3.getTime() < lastUpdate.getTime()); 
  return recent;
}

export const getUserId = () => {
    const session = supabase.auth.session();
    if (!session) {
        throw new Error("missing session");
    }
    const userId = session.user?.id;
    const user = supabase.auth.user();
    if (user?.id !== userId) {
      console.log("NO MATCH: userId, user: ", userId, user);
    }
    if (!userId) {
        throw new Error("missing user.id on session");
    }
    return userId;
}
