import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, IconButton, Input, useDisclosure } from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from "react"
import { supabase } from './supabaseClient'
import { upsert as upsertProfile, fetchSelf } from './model/profiles'
import AvatarEdit from "./AvatarEdit"
import { SettingsIcon } from "@chakra-ui/icons"

const SettingsDrawer = ({ session, showSelf, onChangeShowSelf }: {session: any, showSelf: boolean, onChangeShowSelf: () => void}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef: any = useRef();
  const [loading, setLoading] = useState<boolean>(true)
    const [username, setUsername] = useState<string | null>(null)
    const [avatar_url, setAvatarUrl] = useState<string | null | undefined>(null)
    const [email, setEmail] = useState<string | null>(null)


  useEffect(() => {
    const getProfile = async () => {
      try {
        setLoading(true)
        const user = supabase.auth.user()

        const self = await fetchSelf();

          setUsername(self.username)
          setAvatarUrl(self.avatarURL)
          if (user && user.email && !self.email) {
            await handleEmailAddressUpdate(user.id, user.email);
          }
          setEmail(self.email)
      } catch (error: any) {
        alert(error.message)
      } finally {
        setLoading(false)
      }
    }
    getProfile()
  }, [session])

  const handleEmailAddressUpdate = async (id: string, email: string) => {

    try {
      setLoading(true)

      let { error } = await upsertProfile({id, emailAddress: email});

      if (error) {
        throw error
      }
    } catch (error: any) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleProfileUpdate = async (e?: React.SyntheticEvent) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    try {
      setLoading(true)

      const user = supabase.auth.user();
      if (!user) {
        throw new Error("user is undefined in handleProfileUpdate!");
      }
      console.log('creating profile when one did not exist', avatar_url);
      
      let { error } = await upsertProfile({
        id: user?.id,
        username,
        avatar_url,
        emailAddress: session.user.email
      });

      if (error) {
        throw error
      }
    } catch (error: any) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }
 
  const settingsIcon = <SettingsIcon />
    return (
      <div style={{padding:3, textAlign:"right"}}>
        <IconButton  ref={btnRef} onClick={onOpen} aria-label='polite' icon={settingsIcon} colorScheme='pink' variant='solid' />
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          // finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Update Account Settings</DrawerHeader>
  
            <DrawerBody>
        <form onSubmit={handleProfileUpdate} className="form-widget">
          <div>Email: {email}</div>
          <div>
            <label htmlFor="username">Name</label>
            <Input
              id="username"
              type="text"
              value={username || ''}
              onChange={(e) => setUsername(e.target.value as any)}
            />
          </div>
          <AvatarEdit
            url={avatar_url ?? ""}
            size={50}
            onUpload={async (url: string) => {
              const user = supabase.auth.user();
              if (!user) {
                throw new Error("user is undefined in handleProfileUpdate!");
              }
              setAvatarUrl(url as any)
              await upsertProfile({id: user.id, username, avatar_url: url});
            }}
          />
          <div>
            <Button disabled={loading}>
              Update profile
            </Button>
          </div>
        </form>
      <Button type="button" onClick={() => supabase.auth.signOut()}>
        Sign Out
      </Button>
      <Button onClick={() => {onChangeShowSelf(); }}>{ showSelf ? `hide self in list` : `show self in list` }</Button>
            </DrawerBody>
  
            <DrawerFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='blue'>Save</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </div>
    )
  }

  export default SettingsDrawer;