import { useState } from "react";
import { supabase } from "./supabaseClient";
import TimePicker from "react-time-picker";
import { Button } from "@chakra-ui/react";
import { create as createTimebox, Timebox } from "./model/timeboxes";

const minutesFromNow = (minutes: number) => {
  const now = new Date();
  return new Date(now.getTime() + minutes * 60000);
};

function MyTimeboxFormNew({
  session,
  onSubmit,
}: {
  session: any;
  onSubmit: (t: Timebox) => void;
}) {
  const now = minutesFromNow(30);
  const [saving, setSaving] = useState<boolean>(false);
  const [finishedAt, setFinishedAt] = useState<string>(
    `${now.getHours()}:${now.getMinutes()}`
  );
  const [description, setDescription] = useState<string>("");
  const [location, setLocation] = useState<string>("");

  const clockTimeToDate = (value: string) => {
    if (!value.split) {
      console.log("no split value: ", value);
    }
    const pieces = value.split(":");
    const hour = parseInt(pieces[0], 10);
    const minute = parseInt(pieces[1], 10);
    const dt = new Date();
    dt.setHours(hour);
    dt.setMinutes(minute);
    return dt;
  };

  const setNewFinishedTime = (value: string) => {
    setFinishedAt(value);
  };

  const handleCreateTimebox = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    // TODO disallow an empty description

    const finished_at = clockTimeToDate(finishedAt as any);
    const createdAt = new Date();
    try {
      setSaving(true);
      const user = supabase.auth.user();
      if (!user) {
        throw new Error("cannot create timebox when user is undefined");
      }
      const result = await createTimebox({
        description,
        detail: "",
        location,
        finishedAt: finished_at,
        createdAt,
        userId: user?.id,
        isPrivate: false,
      });
      onSubmit(result);
    } catch (error: any) {
      alert(error.message);
    } finally {
      setSaving(false);
    }
  };
  return (
    <div aria-live="polite">
      {saving ? (
        "Saving ..."
      ) : (
        <div>
          Create a new timebox
          <form onSubmit={handleCreateTimebox} className="form-widget">
            <div>
              <label htmlFor="description">Description</label>
              <input
                id="description"
                type="text"
                value={description || ""}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
            <div>
              <label htmlFor="location">Url</label>
              <input
                id="location"
                type="text"
                value={location || ""}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              />
            </div>
            <div>
              <label htmlFor="finished_at">Finished At</label>
              <TimePicker
                value={finishedAt}
                onChange={(value) => {
                  setNewFinishedTime(value as any);
                }}
              />
            </div>
            <div>
              <Button disabled={saving} type="submit">
                Create Timebox
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default MyTimeboxFormNew;
