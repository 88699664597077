import create from 'zustand';
import { CalendarItem } from '../ApiCalendar';

interface CalendarState {
    isConnected: boolean;
    myItems: CalendarItem[];
    setMyItems: (items: CalendarItem[]) => void;
    setConnected: (connected: boolean) => void;
}

const useStore = create<CalendarState>(set => ({
  isConnected: false,
  myItems: [],
  setConnected: (connected: boolean) => {
      set((state) => {
          return {isConnected: connected};
      })
  },
  setMyItems: (items: CalendarItem[]) => {
      set((state) => {
          return {myItems: items};
      })
  },
}));
export const useCalendarStore = useStore;