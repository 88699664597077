import { CalendarItem, getLocation } from "../ApiCalendar";
import { supabase } from "../supabaseClient";
import { getUserId } from "./profiles";

export interface Timebox {
  id: string | undefined;
  owner_id: string;
  createdAt: Date;
  finishedAt: Date;
  description: string;
  detail: string | undefined;
  location: string | undefined;
  private: boolean;
  finished: boolean;
}

export const fetchAll = async () => {

      let { data, error/*, status*/ } = await supabase
        .from("timebox")
        .select(
          "id, created_at, finished_at, finished, location, description, detail, private, owner_id"
        )
        .eq("finished", false);

      if (error) {
        throw error;
      }
      if (!data) {
        return [];
      }

      let timeboxes = [];
      // GROSS!! we need to improve this later.
      for (let timebox of data) {
        if (new Date(timebox.finished_at) < new Date()) {
          await supabase
            .from("timebox")
            .update({ finished: true })
            .eq("id", timebox.id);
        } else {
          timeboxes.push(fromRow(timebox));
        }
      }
      return timeboxes;

}


export const fetchOne = async (userId: string) => {

      let { data, error, status } = await supabase
        .from("timebox")
        .select(
          `id, created_at, finished_at, finished, location, description, detail, private, owner_id`
        )
        .eq("owner_id", userId)
        .eq("finished", false)
        .single();

      if (error && status !== 406) {
        throw error;
      }

      if (!data) {
        return null;
      }

        const timebox = fromRow(data);
        if (timebox.finished || isFinished(timebox)) {
          // if it's finished, we shouldn't see it.  mark it as finished.
          await updateTimeboxIfFinished(timebox);
          return null;
        }
        return timebox;
};
  
  const fromRow = (data: any): Timebox => {
    const finishedAt = new Date(data.finished_at);
    const finished = new Date().getTime() > finishedAt.getTime();
    const timebox: Timebox = {
      private: data.private,
      description: data.description,
      detail: data.detail,
      location: data.location,
      id: data.id,
      owner_id: data.owner_id,
      finishedAt,
      createdAt: new Date(data.created_at),
      finished,
    };
    return timebox;
  }

  export const isFinished = (timebox: Timebox) => {
    return (timebox.finishedAt.getTime() < new Date().getTime());

  }
  
  export const updateTimeboxIfFinished = async (timebox: Timebox) => {
    if (isFinished(timebox)) {
      timebox.finished = true;
      await supabase.from("timebox").upsert(timebox, {
        returning: "minimal",
      });
    }
  }
  
  export const stop = async (timebox: Timebox) => {
    try {
      await supabase
        .from("timebox")
        .update({ finished: true, finished_at: new Date() })
        .eq("id", timebox?.id);
    } catch (error) {
      console.error(error);
    }
  };

  interface TimeboxCreateProps {
      description: string;
      detail: string | null;
      location: string | null;
      finishedAt: Date;
      createdAt: Date;
      userId: string;
      isPrivate: boolean;
  }
  export const create = async ({
      description,
      detail,
      location,
      finishedAt,
      createdAt,
      userId,
      isPrivate
    }: TimeboxCreateProps) => {
      const detailProp = detail || "";
      const locationProp = location || "";
      const updates = {
        description,
        detail: detailProp,
        location: locationProp,
        finished: false,
        finished_at: finishedAt,
        created_at: createdAt,
        owner_id: userId,
        private: isPrivate,
      };
      console.log("updates: ", updates);

      let { error, data } = await supabase.from("timebox").upsert(updates, {
        returning: "representation",
      });

      if (error) {
        throw error;
      }

      const result: Timebox = {
        description,
        detail: detailProp,
        location: locationProp,
        finished: false,
        id: (data as any)[0].id,
        owner_id: (data as any)[0].owner_id,
        finishedAt,
        createdAt,
        private: isPrivate,
      };
      return result;

  }


export const createFromGoogleCalendarEvent = async (userId: string, calendarItem: CalendarItem, startDateTime: Date, endDateTime: Date) => {
  const location = getLocation(calendarItem);
      const result = await create({
        description: calendarItem.summary,
        detail:  calendarItem.description || "",
        location,
        finishedAt: endDateTime,
        createdAt: startDateTime,
        userId,
        isPrivate: calendarItem.visibility !== "public"
      });
      return result;
}

export const createTimeboxFromCalendar = async (
  hasCurrentTimebox: boolean,
  items: CalendarItem[]
) => {
  if (hasCurrentTimebox || !items[0]) {
    return;
  }
  const first = items[0];
  const startDateTime = new Date(first.start.dateTime);
  const now = new Date();
  if (startDateTime.getTime() < now.getTime()) {
    const endDateTime = new Date(first.end.dateTime);
    if (endDateTime.getTime() > now.getTime()) {
      // it's underway!
      const timebox = await createFromGoogleCalendarEvent(
        getUserId(),
        first,
        startDateTime,
        endDateTime
      );
      //   setMyTimebox(timebox);
      console.log("timebox created: ", timebox);
      return timebox;
    }
  }
};