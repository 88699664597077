import create from 'zustand';
import { Timebox } from '../model/timeboxes';

interface TimeboxState {
    timebox: Timebox | null;
    set: (t: Timebox) => void;
    unset: () => void;

}

const useStore = create<TimeboxState>(set => ({
  timebox: null,
  set: (newTimebox: Timebox) => {
      set((state) => {
          if (state.timebox) {
              throw new Error("timebox is already set!");
          }
          return {timebox: newTimebox};
      })
  },
  unset: () => {
      set((state) => {
          return {timebox: null};
      })
  }
}));
export const useMyTimeboxStore = useStore;