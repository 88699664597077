import { useState, useEffect } from 'react'
import { supabase } from './supabaseClient'
import Auth from './Auth'
// import Account from './Account'
import TimeboxList from './TimeboxList'
import { useBoolean } from 'usehooks-ts'
import SettingsDrawer from './SettingsDrawer'
import TimeboxDrawer from './TimeboxDrawer'
import { Flex, IconButton } from '@chakra-ui/react'
import { Calendar } from './Calendar'
import { useMyTimeboxStore } from "./store/MyTimebox";
import { useMyProfileStore } from "./store/MyProfile";
import { fetchSelf, getUserId } from './model/profiles'
import MyTimeboxView from './MyTimeboxView'
import { stop } from "./model/timeboxes";
import CalendarDrawer from './CalendarDrawerNew'
import { CalendarIcon } from '@chakra-ui/icons'
import { useCalendarStore } from "./store/Calendar";
import { find } from 'lodash'
import NextCalendarItem from './NextCalendarItem'

export default function App() {
  const [session, setSession] = useState(null)
  const {value: showSelf, toggle: toggleShowSelf} = useBoolean(false);
  const { timebox: myTimebox, set: setMyTimebox, unset: unsetMyTimebox } = useMyTimeboxStore()
  const { profile: myProfile, set: setMyProfile } = useMyProfileStore()
  const { myItems: myCalendarItems } = useCalendarStore();

  useEffect(() => {
    const getProfile = async () => {
      console.log('getting profile');
      
      try {
        const self = await fetchSelf();
        console.log("self: ", self);
        setMyProfile(self);
      } catch (error: any) {
        alert(error.message)
      }
    }
    setSession(supabase.auth.session() as any)
    getProfile()

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session as any)
    })
  }, [setMyProfile])


  const showAccount = !!session;
  if (!showAccount) {
    return (

      <div className="container" style={{ padding: '50px 0 100px 0' }}>
        <Auth />
      </div>
    )
  }

  let nextItem = null;
  if (myCalendarItems) {
    nextItem = find(myCalendarItems, (item) => {
      const startDateTime = new Date(item.start.dateTime);
      const now = new Date();
      return (startDateTime.getTime() > now.getTime());
    });
  }

  const calendarIcon = <CalendarIcon />
  const iconButton = <IconButton aria-label='polite' icon={calendarIcon} colorScheme='pink' variant='solid' />

  return (
    <div className="container" style={{ padding: '50px 0 100px 0', maxWidth: 400 }}>
      <Flex style={{padding: 18}}>
      <SettingsDrawer 
        session={session}
        showSelf={showSelf}
        onChangeShowSelf={() => {toggleShowSelf()}}
        />
      <TimeboxDrawer 
        session={session}
        />
      {myProfile ? <><CalendarDrawer button={iconButton} profile={myProfile} /></> : null }
      </Flex>
      <div style={{padding:5}}>
        <Calendar profileId={getUserId()} myTimebox={myTimebox} setMyTimebox={setMyTimebox} />
      </div>
      {myTimebox ? 
        <MyTimeboxView timebox={myTimebox} onStop={() => {
          stop(myTimebox);
          unsetMyTimebox();
        }} /> : null
      }
      {
        nextItem ?
          <NextCalendarItem item={nextItem} /> : null
      }
      <TimeboxList
        key={`timebox-${(session as any).user?.id}`}
        session={session}
        showSelf={showSelf}
      />
    </div>
  )
}