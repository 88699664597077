// import Avatar from "./Avatar";
import { DateTime } from "luxon";
import { getTimezoneOffset } from "./timezone";
import { Profile, recent } from "./model/profiles";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import Avatar from "./Avatar";
import { Timebox } from "./model/timeboxes";
import CalendarDrawer from "./CalendarDrawerOld";
import { relativeTime } from "./util/dateTime";

interface UserTimeProps {
  timezone?: string;
  timezoneOffset?: number; // in minutes
  myOffset: number; // in minutes
}
const userTime = ({timezone, timezoneOffset, myOffset}: UserTimeProps) => {
  if (!timezoneOffset) {
    return null;
  }
  const now = new Date();
  const offsetDelta = myOffset - timezoneOffset;
  if (offsetDelta === 0) {
    return null;
  }
  const localTimeMs = now.getTime() + (offsetDelta * 60 * 1000);  
  const localTime = new Date();
  localTime.setTime(localTimeMs);
  const myLocale = navigator.language;
  const timeString = localTime.toLocaleString(myLocale, DateTime.TIME_SIMPLE);
  const hourDiff = Math.floor(offsetDelta / 60);
  const hourDiffRemainderMinutes = offsetDelta - (hourDiff * 60);
  const hourDiffString = hourDiffRemainderMinutes ? 
                          `${hourDiff}h${hourDiffRemainderMinutes}m` :
                          `${hourDiff}h `;
  const deltaIcon = offsetDelta > 0 ? "+" : "";
  return `${timeString} (${deltaIcon}${hourDiffString}, ${timezone})`;
} 


interface ActivityProps {
  timebox: Timebox;
}
const Activity = ({timebox}: ActivityProps) => {
  return <div><Text>
          { timebox.description }
          </Text>
         <Text fontSize={"small"}>
          {`ends ${relativeTime(timebox.finishedAt)}`}
        </Text>
        </div>
}

interface UserRowProps {
  user: Profile;
  timebox?: Timebox;
}

const UserRow = ({ user, timebox }: UserRowProps) => {
  const online = recent(user.updatedAt);
  let offlineSinceMessage;
  if (!online) {
    offlineSinceMessage = `offline since: ${relativeTime(
      user.updatedAt
    )}`;
  }
  const localTimeMessage = userTime({
    timezone: user.timezone,
    timezoneOffset: user.timezoneOffset,
    myOffset: getTimezoneOffset(),
  });
  return (
      <Flex mt={3} ml={3}>
        <Tooltip label={offlineSinceMessage} fontSize='small' color={"GrayText"} >
          <div>
            <Avatar url={user.avatarURL} online={online} />
          </div>
        </Tooltip>
        <Box ml="3">
          <Text fontWeight="bold">{user.username}</Text>
          <Text fontSize="small" mt={0.5} color={"GrayText"}>{localTimeMessage}</Text>
          {online && timebox ? <Activity timebox={timebox} /> : null}
        </Box>
        <CalendarDrawer profile={user} />
      </Flex>
  );
};

// const UserRow2 = ({ user, timebox }: UserRowProps) => {
//   return (
//     <div
//       style={{ display: "flex", flexDirection: "column", marginBottom: "5%" }}
//     >
//       <span><Avatar url={user.avatarURL} size={40} />{user.username}</span>
//       <UserTime timezone={user.timezone}
//                 timezoneOffset={user.timezoneOffset}
//                 myOffset={getTimezoneOffset()}
//                 />
//       <Activity lastUpdate={user.updatedAt} />
//       <span>{" - "}</span>
//       <span>{timebox ? timebox.description : "No Pomodoro"}</span>
//       <span>{timebox ? timebox.finishedAt.toString() : ""}</span>
//     </div>
//   );
// };

export default UserRow;