import { CalendarItem } from "./ApiCalendar";

const NextCalendarItem = ({item}: {item: CalendarItem}) => {
    const start = new Date(item.start.dateTime);
    return <div>
        Next Up...< br /> 
        {item.summary} <br />
        {start.toLocaleTimeString()}
    </div>

}

export default NextCalendarItem;