import { Divider } from "@chakra-ui/react";
import { CalendarItem, getLocation } from "./ApiCalendar";
import { prettyDate } from "./util/dateTime";
import EventModal from "./EventModal";
import ToLink from "./ToLink";

  export interface CalendarItemProps {
    id: string;
    start: Date;
    end: Date;
    summary: string;
    location?: string | null;
    description?: string;
    attendeeEmailAddresses: string[];
    wholeItem: CalendarItem;
  }

  const CalendarRow = (props: CalendarItemProps) => {
    return <div>
      <h4>{props.summary}</h4>
      <p><small>{`${prettyDate(props.start)} - ${prettyDate(props.end)}`}</small></p>
      <p><small><ToLink>{props.location}</ToLink></small></p>
      <EventModal event={props} />
      <Divider />
    </div>;
  }

  export const CalendarItemList = (props: {items: CalendarItem[]}) => {
    return <div>
      {props.items.map((item) => {
        const end = new Date(item.end.dateTime);
        if (end.getTime() < (new Date()).getTime()) {
          return null;
        }
        const attendeeEmailAddresses = (item.attendees || []).map(a => a.email);
        
        return <CalendarRow
          summary={item.summary} 
          id={item.id} 
          location={getLocation(item)} 
          description={item.description} 
          start={new Date(item.start.dateTime)}
          end={end}
          attendeeEmailAddresses={attendeeEmailAddresses}
          wholeItem={item}

          />
      })}
    </div> 
  }