import hdate from 'human-date';

export const isToday = (someDate: Date) => {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
  }


export const relativeTime = (someDate: Date) => {
    return hdate.relativeTime(someDate);
}

export const prettyTime = (someDate: Date) => {
    return someDate.toLocaleTimeString();
}

export const prettyDate = (someDate: Date) => {
    if (!isToday(someDate)) {
        return someDate.toLocaleString();
    }
    return prettyTime(someDate);
}