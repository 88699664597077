import { CalendarResult } from '../ApiCalendar'
import { supabase } from '../supabaseClient'

interface CalendarResponse {
    result: CalendarResult;
    modifiedAt: Date;
    userId: string;
}

export const get = async (profileId: string): Promise<CalendarResponse | null> => {

        const row = await supabase
          .from('googleCalendar')
          .select(`user_id, modified_at, response`)
          .eq('user_id', profileId)
          .single() 

        if (row.error) {
          if (row.error.message === "JSON object requested, multiple (or no) rows returned") {
            return null;
          }
          console.log("error from googleCalendar.get row.error: ", row.error);
          throw row.error;
        }

        if (!row.data) {
            throw new Error("no data in response");
        }

        return rowToResponse(row.data);

}

export const set = async (profileId: string, response: CalendarResult) => {
    const update = {
      user_id: profileId,
      response,
      modified_at: new Date(),
    };
    await supabase.from("googleCalendar").upsert(update, {
      returning: "minimal",
    }).eq("user_id", profileId);

}

const rowToResponse = (row: any) => {
    const response: CalendarResponse = {
        result: row.response,
        userId: row.user_id,
        modifiedAt: row.modifiedAt
    };
    return response;

}


