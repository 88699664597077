import React, { useCallback, useEffect, useState } from "react";
import { supabase } from "./supabaseClient";
import { flatten, groupBy, keyBy } from "lodash";
import UserRow from "./UserRow";
import { fetchOthers, touch as touchProfile, Profile, fetchUsers, recent } from "./model/profiles";
import { useInterval } from "usehooks-ts";
import { useMyTimeboxStore } from "./store/MyTimebox";
import { fetchAll as fetchTimeboxes, fetchOne as fetchTimebox, Timebox, isFinished, updateTimeboxIfFinished } from "./model/timeboxes";

export interface SessionUser {
  id: string;
  email: string;
}
export interface Session {
  user: SessionUser;
}

function TimeboxList({ session, showSelf }: { session: Session, showSelf: boolean }) {
  const [loading, setLoading] = useState<Boolean>(true);
  const [timeboxes, setTimeboxes] = useState<Array<Timebox>>([]);
  // const [calendarResult, setCalendarResult] = useState<CalendarResult | null>(null);
  const [users, setUsers] = useState<Array<Profile>>([]);
const { timebox: myTimebox, set: setMyTimebox, unset: unsetMyTimebox } = useMyTimeboxStore()

  const getTimeboxes = useCallback(async () => {
    try {
      const timeboxes = await fetchTimeboxes();
      setTimeboxes(timeboxes);
    } catch (error) {
      console.log(error);
    }
  }, []);
  
  const getUsers = useCallback(async () => {
    try {
      const users = showSelf ? await fetchUsers() : await fetchOthers();
      setUsers(users);
    } catch (error) {
      console.error(error);
    }
  }, [showSelf]);

  
  useEffect(() => {
  const getMyTimebox = async () => {
    try {
      setLoading(true);
      const user = supabase.auth.user();
      if (!user || !user.id) {
        throw new Error("cannot get timebox for unknown user");
      }
      const timebox = await fetchTimebox(user?.id);
      if (timebox) {
        setMyTimebox(timebox);
      } else {
        unsetMyTimebox();
      }
    } catch (error: any) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

    getMyTimebox();
    getTimeboxes();
    getUsers();
  }, [session, getUsers, getTimeboxes, setMyTimebox, unsetMyTimebox]);

  useInterval(() => {
    touchProfile();
    if (!myTimebox) {
      return;
    }
    if (isFinished(myTimebox)){
      updateTimeboxIfFinished(myTimebox);
      unsetMyTimebox();
    }
  }, 1000);

  useInterval(() => {
    getUsers();
    getTimeboxes();
  }, 60 * 1000);

  return (
    <div>
      <div>
        <Timeboxes timeboxes={timeboxes} users={users} />
      </div>
      { loading ? "Loading..." : ""}
    </div>
  );


}

interface Row {
  user: Profile;
  timebox: Timebox;
}

const alphaSort = (rows: Row[]) => {
  return rows.sort((row1, row2) => {
    if (row1.user.email > row2.user.email) return 1;
    if (row1.user.email < row2.user.email) return -1;
    return 0;
  });
}

const sortRows = (rows: Row[]) => {
  if (1 + 1 === 2) return alphaSort(rows); // TODO uncomment this and fix the sort

  const groupedRecent = groupBy(rows, (row) => {
    return recent(row.user.updatedAt);
  });
  const groupedTimebox = groupBy(groupedRecent['true'], (row) => {
    return !!row.timebox;
  });
  return flatten([
    alphaSort(groupedTimebox["true"]),
    alphaSort(groupedTimebox["false"]),
    alphaSort(groupedRecent["false"])
  ]);

}

interface TimeboxesProps {
  timeboxes: Array<Timebox>;
  users: Array<Profile>;
}
const Timeboxes = ({ timeboxes, users }: TimeboxesProps) => {
  const timeboxMap = keyBy(timeboxes, "owner_id");
  const rows = users.map((user) => {
    const timebox: Timebox | null = timeboxMap[user.id!];
    return {user, timebox};
  });
  return (
    <div>
      {sortRows(rows).map(({user, timebox}) => {
        return <UserRow key={user.id} user={user} timebox={timebox} />;
      }
      )}
    </div>
  );
};

export default TimeboxList;
