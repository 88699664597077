import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, IconButton, useDisclosure } from "@chakra-ui/react"
import React, { useEffect, useRef, useState} from "react"
import { CalendarIcon } from "@chakra-ui/icons"
import { CalendarItem } from "./ApiCalendar"
import { get as getCalenderResponse } from "./model/googleCalendar";
import { Profile } from "./model/profiles"
import { CalendarItemList } from "./CalendarItemList"

type IconSize = "xs" | "sm" | "md" | "lg"; 

const CalendarDrawer = ({profile, iconSize}: {profile: Profile, iconSize?: IconSize}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef: any = useRef();
    const [calendarItems, setCalendarItems] = useState<CalendarItem[]>([])

  useEffect(() => {
    const fetchSet = async () => {
      const response = await getCalenderResponse(profile.id!);
      if (response?.result.items){
        setCalendarItems(response.result.items);
      } else {
        console.log("no items? response.result.items: ", response);
      }
      
    }
    fetchSet();

  });

  const calendarIcon = <CalendarIcon />
    return (
      <div style={{paddingLeft:8, textAlign:"right"}}>
        <IconButton size={iconSize || "sm"}  ref={btnRef} onClick={onOpen} aria-label='polite' icon={calendarIcon} colorScheme='pink' variant='solid' />
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          // finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Calendar for {profile.username}</DrawerHeader>
  
            <DrawerBody>
              <CalendarItemList items={calendarItems} />
            </DrawerBody>
  
            <DrawerFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='blue'>Save</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </div>
    )
  }

  export default CalendarDrawer;