import ApiCalendar, { CalendarResult } from "./ApiCalendar";
import { useInterval } from "usehooks-ts";
import GoogleCalendarConnectionView from "./GoogleCalendarConnectionView";
import { Timebox, createTimeboxFromCalendar } from "./model/timeboxes";
import { set as writeCalendar } from "./model/googleCalendar";
import { useCalendarStore } from "./store/Calendar";
const apiCalendar = new ApiCalendar();


const fetchCalendarResult = async () => {
  const response = await apiCalendar.listUpcomingEvents(10);
  if (!response || !response.result) {
    console.log("broken response: ", response);
    return null;
  }
  const result: CalendarResult = response.result;
  if (!result || !result.items) {
    console.log("broken result: ", result);
    return null;
  }
  return result;
};

interface CalendarProps {
  myTimebox?: Timebox | null;
  setMyTimebox: (t: Timebox) => void;
  profileId: string;
}

export const Calendar = ({ myTimebox, setMyTimebox, profileId }: CalendarProps) => {
  const { setMyItems: setCalendarItems } = useCalendarStore();

  const probeGoogleCalendar = async (hasCurrentTimebox: boolean, profileId: string) => {
    if (apiCalendar.sign) {
      const calendarResult = await fetchCalendarResult();
      if (calendarResult) {
        await writeCalendar(profileId, calendarResult);
      }
      const calendarItems = calendarResult?.items || [];
      const timebox = await createTimeboxFromCalendar(
        hasCurrentTimebox,
        calendarItems
      );
      setCalendarItems(calendarItems);
      if (timebox) {
        setMyTimebox(timebox);
      }
    }
  };

  useInterval(async () => {
    await probeGoogleCalendar(!!myTimebox, profileId);
  }, 60 * 1000);

  const authClick = async (hasCurrentTimebox: boolean) => {
    try {
      await apiCalendar.handleAuthClick();
    } catch (ex) {
      console.log("sign in failed: ", ex);
      return;
    }
    console.log("sign in successful!");
    await probeGoogleCalendar(!!myTimebox, profileId);
  };

  return (
    <GoogleCalendarConnectionView
      connected={apiCalendar.sign}
      connect={() => {
        authClick(!!myTimebox);
      }}
    />
  );
};