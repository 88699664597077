import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, IconButton, useDisclosure } from "@chakra-ui/react"
import React, { useRef} from "react"
import { TimeIcon } from "@chakra-ui/icons"
import MyTimeboxFormNew from "./MyTimeboxFormNew"
import { useMyTimeboxStore } from "./store/MyTimebox"

const TimeboxDrawer = ({session}: {session: any}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef: any = useRef();
    const { set: setMyTimebox } = useMyTimeboxStore()


  const timeIcon = <TimeIcon />
    return (
      <div style={{padding: 3, textAlign:"right"}}>
        <IconButton  ref={btnRef} onClick={onOpen} aria-label='polite' icon={timeIcon} colorScheme='pink' variant='solid' />
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          // finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Create Timebox</DrawerHeader>
  
            <DrawerBody>
        <div style={{ marginTop: 50 }}>
          <MyTimeboxFormNew
            session={session}
            onSubmit={(t) => {
              setMyTimebox(t);
            }}
          />
        </div>

            </DrawerBody>
  
            <DrawerFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='blue'>Save</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </div>
    )
  }

  export default TimeboxDrawer;