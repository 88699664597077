
import Linkify from 'linkify-react';

const ToLink = ({children, underline}: {children: React.ReactNode, underline?: boolean}) => {
    if (underline === false) {
        return <Linkify>{children}</Linkify>
    }
    return <u><Linkify>{children}</Linkify></u>;
}

export default ToLink;