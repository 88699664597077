import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, useDisclosure } from "@chakra-ui/react"
import React, { useEffect, useState} from "react"
import { CalendarItem } from "./ApiCalendar"
import { get as getCalenderResponse } from "./model/googleCalendar";
import { Profile } from "./model/profiles"
import { CalendarItemList } from "./CalendarItemList"

interface CalendarDrawerProps {
  profile: Profile,
  button: any,
}

const NewCalendarDrawer = ({profile, button}: CalendarDrawerProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [calendarItems, setCalendarItems] = useState<CalendarItem[]>([])

  useEffect(() => {
    const fetchSet = async () => {
      const response = await getCalenderResponse(profile.id!);
      if (response?.result.items){
        setCalendarItems(response.result.items);
      } else {
        console.log("no items? response.result.items: ", response);
      }
      
    }
    fetchSet();

  });

  button.props.onClick = () => {onOpen(); console.log('clicked!');
  };
    return (
      <>
      {button}
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Calendar for {profile.username}</DrawerHeader>
  
            <DrawerBody>
              <CalendarItemList items={calendarItems} />
            </DrawerBody>
  
            <DrawerFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='blue'>Save</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    )
  }

  export default NewCalendarDrawer;