type Timezone = string;

export const getTimezone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone as Timezone;
};

type TimezoneOffset = number;
export const getTimezoneOffset = () => {
  const offset = new Date().getTimezoneOffset();
  return offset as TimezoneOffset;
};
